<template>
  <div class="layout-dashboard">
    <div class="d-flex h-100">
      <Sidebar/>
      <router-view class="flex-grow-1 overflow-auto" />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/components/Sidebar.vue';

export default {
  name: 'LayoutDashboard',
  components: {
    Sidebar,
  },
  data () {
    return {

    };
  },
};
</script>

<style>

</style>
