import $axios from '@/libs/OAuth2/services/axios.js';
import md5 from 'md5';

const emailToMd5 = email => md5(email.toString().toLowerCase());

export default {
  getStatus: () => $axios.get('/status'),

  // Password recovery
  getChangePasswordToken: email => $axios.post(`/users/${emailToMd5(email)}/changePasswordTokens`),
  changePasswordWithToken: (email, token, password) => $axios.post(`/users/${email}/password`, { token, password }),

  // My profile
  getMyProfile: () => $axios.get('/profile'),
  updateMyProfile: user => $axios.put('/profile', user),
  changeMyPassword: (password, newPassword) => $axios.post('/profile/password', { password, newPassword }),

  /* FIN */
  // Setup
  getSetup: () => $axios.get('/fin/setup'),
  updateSetup: (form, isCompleted) => $axios.put('/fin/setup', { form, isCompleted }),
  fetchSuggestions: (search) => $axios.get(`/geo/towns/autocomplete?search=${search}`),
  fetchAtecoList: (search) => $axios.get(`/ateco/autocomplete?search=${search}`),
  createCompany: (name, vat, fiscalCode, legalAddress, legalProvince, legalCity, legalCAP, operationalAddress, operationalProvince, operationalCity, operationalCAP) => $axios.post('/fin/companies', { name, vat, fiscalCode, legalAddress, legalProvince, legalCity, legalCAP, operationalAddress, operationalProvince, operationalCity, operationalCAP }),

  // Company
  getCompany: id => $axios.get(`/fin/companies/${id}`),

  // Bank Accounts
  createBank: (companyId, iban, name, balance, fido, colour, isMain, isCash) => $axios.post(`/fin/companies/${companyId}/banks`, { iban, name, balance, fido, colour, isMain }),
  updateBank: (id, iban, name, balance, fido, colour, isMain) => $axios.put(`/fin/banks/${id}`, { iban, name, balance, fido, colour, isMain }),
  fetchBanksList: () => $axios.get('/fin/bank-details'),
  createBanks: (companyId, banks, cashAmount) => $axios.post(`/fin/companies/${companyId}/create-banks`, { banks, cashAmount }),

  // Dashboard
  fetchSaldiDashboard: (companyId) => $axios.get(`/fin/companies/${companyId}/balances`),
  fetchImpegniDashboard: (companyId) => $axios.get(`/fin/companies/${companyId}/promisesums`),

  // Promises (Movimenti in attesa)
  createPromise: (companyId, counterpart, amount, date, description, type = null, bankId, otherData = null) => $axios.post(`/fin/companies/${companyId}/promises`, { amount, counterpart, date, description, type, bankId, ...otherData }),
  updatePromise: (id, amount, date, description, type, bankId) => $axios.put(`/fin/promises/${id}`, { amount, date, description, type, bankId }),
  deletePromise: (id) => $axios.delete(`/fin/promises/${id}`),

  // Movements
  createMovement: (companyId, amount, date, description, type, bankId) => $axios.post(`/fin/companies/${companyId}/movements`, { amount, date, description, type, bankId }),
  updateMovement: (id, amount, date, description, type, bankId) => $axios.put(`/fin/movements/${id}`, { amount, date, description, type, bankId }),
  deleteMovement: (movementId) => $axios.delete(`/fin/movements/${movementId}`),

  // Riconciliazione con Fabrick
  getMovements: (companyId, filters) => $axios.get(`/fin/companies/${companyId}/movements`, { params: filters }),
  createMatch: (companyId, promiseId, movementId) => $axios.post(`/fin/companies/${companyId}/match`, { promiseId, movementId }),
  fabrickOnboarding: (form, companyId = null) => $axios.post(`fin/companies/${companyId}fabrick/onboarding`, { form }),

  // Prima Nota
  getPrimeEntry: (companyId, filters) => $axios.get(`/fin/companies/${companyId}/primanota`, { params: filters }),
  deleteMatch: (movementId) => $axios.delete(`/fin/matches/${movementId}`),
  downloadPrimeEntry: (companyId, filters, type) => $axios.get(`/fin/companies/${companyId}/primanota/download`, { responseType: 'blob', params: { ...filters, type } }),

  // Riconciliazione manuale
  fetchPromisesDetails: (companyId, filters) => $axios.get(`/fin/companies/${companyId}/promises`, { params: filters }),
  createAccountedMovement: (companyId, amount, date, description, type, bankId, promiseId = null, rettificaAmount) => $axios.post(`/fin/companies/${companyId}/movements/accounted`, { amount, date, description, type, bankId, promiseId, rettificaAmount }),

  // Scadenzario
  fetchScadenzarioAttivo: (companyId, filters) => $axios.get(`/fin/companies/${companyId}/scadenzario-attivo`, { params: filters }),

  // Finanziamenti
  getListLoans: (companyId) => $axios.get(`/fin/companies/${companyId}/finanziamenti`),
  fetchLoan: id => $axios.get(`/fin/finanziamenti/${id}`),
  deleteLoan: id => $axios.delete(`/fin/finanziamenti/${id}`),
  generateInstalmentLoan: (piano) => $axios.get('/fin/rate', { params: piano }),
  createLoan: (
    companyId,
    label,
    bankId,
    isPaid,
    isCalculated,
    importo,
    paidDate,
    frequenzaRate,
    tasso,
    tipoPianoId,
    nbRate,
    primaScadenza,
    nbRatePreammortamento,
    primaScadenzaPreammortamento,
    fineMese,
    iva,
    maxiRataIniziale,
    maxiRataFinale,
    rate,
    id = null,
  ) => $axios.post(`/fin/companies/${companyId}/finanziamenti`, {
    label,
    bankId,
    isPaid,
    isCalculated,
    importo,
    paidDate,
    frequenzaRate,
    tasso,
    tipoPianoId,
    nbRate,
    primaScadenza,
    nbRatePreammortamento,
    primaScadenzaPreammortamento,
    fineMese,
    iva,
    maxiRataIniziale,
    maxiRataFinale,
    rate,
    id,
  }),
  updateLoan: (id, nRata, edit, result, tasso = null) => $axios.put(`/fin/finanziamenti/${id}`, { nRata, edit, result, tasso }),
  setPaidInstalment: (id, nRata, isPaid = true) => $axios.patch(`/fin/finanziamenti/${id}/rate`, { nRata, isPaid }),

  // Fatture passive
  getListInvoices: (companyId, filters, sign) => $axios.get(`/fin/companies/${companyId}/fatture?sign=${sign}`, { params: filters }), // ?sign=in/out
  fetchInvoice: (id) => $axios.get(`/fin/fatture/${id}`),
  deleteInvoice: (id) => $axios.delete(`/fin/fatture/${id}`),
  createInvoice: (
    companyId,
    number,
    denomination,
    vat,
    fiscalCode,
    address,
    city,
    cap,
    province,
    state,
    taxable, // imponibile
    iva,
    total,
    date,
    withholding,
    withholdingBankId,
    sign,
    rate,
    leftAmount,
    id = null,
  ) =>
    $axios.post(`/fin/companies/${companyId}/fatture`, {
      number,
      denomination,
      vat,
      fiscalCode,
      address,
      city,
      cap,
      province,
      state,
      taxable,
      iva,
      total,
      date,
      withholding,
      withholdingBankId,
      sign,
      rate,
      leftAmount,
      id,
    }),
  uploadXmlInvoice: (companyId, xml, sign) => $axios.post(`/fin/companies/${companyId}/fatture/xml/${sign}`, xml),
  uploadZipInvoice: (companyId, zip, sign) => $axios.post(`/fin/companies/${companyId}/fatture/zip/${sign}`, zip, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  confirmInvoice: id => $axios.post(`/fin/fatture/${id}`),

  // Registrazione e pagamenti
  createNewUser: form => $axios.post('/users', form),
  confirmUserSubscription: (emailMd5, token) => $axios.post(`/users/${emailMd5}/confirmation/${token}`),
  buyALicense: () => $axios.post('/checkout'),
  getBillingAddress: () => $axios.get('/billing'),

  // Personale
  getSalary: (companyId) => $axios.get(`/fin/companies/${companyId}/salary`),
  updateSalary: (companyId, amountCumulated, day, salaryMonths, f24Amount, bankId, f24BankId, employees) => $axios.put(`/fin/companies/${companyId}/salary`, { amountCumulated, day, salaryMonths, f24Amount, bankId, f24BankId, employees }),

};
