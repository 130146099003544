<template>
  <div>
    <AddebitiAccrediti v-if="selectedMovement === 0" :banks="banks" :companyId="companyId" :in_out="null" @save="$emit('save')"/>
    <PagamentoBonifico v-if="selectedMovement === 1" :banks="banks" :companyId="companyId" :in_out="'out'" @save="$emit('save')" />
    <PagamentoFiscale v-if="selectedMovement === 2" :banks="banks" :companyId="companyId" :in_out="'out'" @save="$emit('save')" />
    <CompetenzeOneri v-if="selectedMovement === 3" :banks="banks" :companyId="companyId" @save="$emit('save')" />
    <Girofondo v-if="selectedMovement === 4" :banks="banks" :companyId="companyId" :in_out="null" @save="$emit('save')" />
    <RiBaAttivaOTitoloDiCredito v-if="selectedMovement === 5" :banks="banks" :companyId="companyId" :in_out="null" @save="$emit('save')" />
    <VersamentiPrelievi v-if="selectedMovement === 6" :banks="banks" :companyId="companyId" :in_out="null" @save="$emit('save')" />
  </div>
</template>

<script>
import AddebitiAccrediti from '@/views/components/Forms/Movements/Forms/AddebitiAccrediti.vue';
import PagamentoFiscale from '@/views/components/Forms/Movements/Forms/PagamentoFiscale.vue';
import RiBaAttivaOTitoloDiCredito from '@/views/components/Forms/Movements/Forms/RiBaAttivaOTitoloDiCredito.vue';
import PagamentoBonifico from '@/views/components/Forms/Movements/Forms/PagamentoBonifico.vue';
import VersamentiPrelievi from '@/views/components/Forms/Movements/Forms/VersamentiPrelievi.vue';
import Girofondo from '@/views/components/Forms/Movements/Forms/Girofondo.vue';
import CompetenzeOneri from '@/views/components/Forms/Movements/Forms/CompetenzeOneri.vue';

export default {
  props: {
    movement: Number,
    banks: Array,
    companyId: String,
  },
  components: {
    AddebitiAccrediti,
    PagamentoFiscale,
    RiBaAttivaOTitoloDiCredito,
    PagamentoBonifico,
    VersamentiPrelievi,
    Girofondo,
    CompetenzeOneri,
  },
  data () {
    return {
      types: [
        { type: 'girofondi', name: 'Girofondo', in_out: null },
        { type: 'addebiti_accrediti', name: 'Altro Accredito/Addebito', in_out: null },
        { type: 'altri_bonifici', name: 'Pagamento Bonifico', in_out: 'out' },
        { type: 'competenze_oneri', name: 'Competenze e Oneri Bancari', in_out: 'out' },
        { type: 'pagamenti_fiscali', name: 'Pagamento Fiscale', in_out: 'out' },
        { type: 'versamenti_prelievi', name: 'Versamento/Prelievo', in_out: null },
        { type: 'riba_titoli', name: 'Ri.Ba o titolo di credito', in_out: null },
      ],
    };
  },
  computed: {
    selectedMovement () {
      return this.movement;
    },
  },
};
</script>

<style>

</style>
