<template>
<div class="select-movement">
    <form
      v-if="showSelectType"
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <h2 class="mb-4">Seleziona un movimento</h2>

      <b-form-group
        class="mb-3"
        id="input-group-type"
        :class="{ invalid: !isValidType && showErrorType }"
      >
        <vue-select v-model="type" :options="formattedTypes" label="name" placeholder="Seleziona un tipo..." :clearable="false" :dropdownShouldOpen="true">
          <template v-slot:no-options>
              <div class="text-center py-2">
                Nessun tipo trovato
              </div>
          </template>
        </vue-select>
        <b-form-invalid-feedback :state="false" :class="{ invisible: isValidType || !showErrorType }" >
            <i class="fas fa-exclamation-circle"></i> Seleziona un tipo di movimento
        </b-form-invalid-feedback>
      </b-form-group>
      <custom-button
          :isLoading="loading"
          label="Crea"
          class="w-100"
          @click.prevent.native="onSubmit"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>

    <div v-else>
      <AddebitiAccrediti :banks="banks" :companyId="companyId" :in_out="type.in_out" @save="$emit('save', 'addebiti_accrediti')" v-if="type.type === 'addebiti_accrediti'"/>
      <PagamentoBonifico :banks="banks" :companyId="companyId" :in_out="type.in_out" @save="$emit('save', 'altri_bonifici')" v-if="type.type === 'altri_bonifici'"/>
      <PagamentoFiscale :banks="banks" :companyId="companyId" :in_out="type.in_out" @save="$emit('save', 'pagamenti_fiscali')" v-else-if="type.type === 'pagamenti_fiscali'"/>
      <RiBaAttivaOTitoloDiCredito :banks="banks" :companyId="companyId" :in_out="type.in_out" @save="$emit('save', 'riba_titoli')" v-else-if="type.type === 'riba_titoli'" />
      <VersamentiPrelievi :banks="banks" :companyId="companyId" :in_out="type.in_out" @save="$emit('save', 'versamenti_prelievi')" v-else-if="type.type === 'versamenti_prelievi'" />
      <Girofondo :banks="banks" :companyId="companyId" :in_out="type.in_out" @save="$emit('save', 'girofondi')" v-if="type.type === 'girofondi'"/>
      <CompetenzeOneri :banks="banks" :companyId="companyId" @save="$emit('save', 'competenze_oneri')" v-if="type.type === 'competenze_oneri'"/>
    </div>
</div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import Select from 'vue-select';
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';
import AddebitiAccrediti from './Forms/AddebitiAccrediti.vue';
import PagamentoFiscale from './Forms/PagamentoFiscale.vue';
import RiBaAttivaOTitoloDiCredito from './Forms/RiBaAttivaOTitoloDiCredito.vue';
import PagamentoBonifico from './Forms/PagamentoBonifico.vue';
import VersamentiPrelievi from './Forms/VersamentiPrelievi.vue';
import Girofondo from './Forms/Girofondo.vue';
import CompetenzeOneri from './Forms/CompetenzeOneri.vue';

export default {
  props: {
    banks: Array,
    companyId: String,
  },
  components: {
    ErrorCard,
    'custom-button': Button,
    'vue-select': Select,
    'b-form-group': BFormGroup,
    'b-form-invalid-feedback': BFormInvalidFeedback,
    AddebitiAccrediti,
    PagamentoBonifico,
    PagamentoFiscale,
    RiBaAttivaOTitoloDiCredito,
    VersamentiPrelievi,
    Girofondo,
    CompetenzeOneri,
  },
  data () {
    return {
      showErrorType: false,
      loading: false,
      errorMessage: '',
      showSelectType: true,

      // Form
      /**
       *        // { type: 'anticipo_entrata', name: 'Accredito Anticipo', in_out: 'in' },
       *         //{ type: 'anticipo_uscita', name: 'Estinzione Anticipo', in_out: 'out' },
      */
      type: '',
      types: [
        { type: 'girofondi', name: 'Girofondo', in_out: null },
        { type: 'addebiti_accrediti', name: 'Altro Accredito/Addebito', in_out: null },
        { type: 'altri_bonifici', name: 'Pagamento Bonifico', in_out: 'out' },
        { type: 'competenze_oneri', name: 'Competenze e Oneri Bancari', in_out: 'out' },
        { type: 'pagamenti_fiscali', name: 'Pagamento Fiscale', in_out: 'out' },
        { type: 'versamenti_prelievi', name: 'Versamento/Prelievo', in_out: null },
        { type: 'riba_titoli', name: 'Ri.Ba o titolo di credito', in_out: null },
      ],
    };
  },
  computed: {
    formattedTypes () {
      let typesToShow = this.types;
      if (this.banks.length <= 1) {
        typesToShow = typesToShow.filter((item) => item.type !== 'girofondi');
      }
      return typesToShow.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    },
    isValidType () {
      return isFilled(this.type);
    },
  },
  methods: {
    onSubmit: function () {
      this.errorMessage = '';
      if (!this.isValidType) {
        this.showErrorType = true;
      } else {
        this.showSelectType = false;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
