export const isFilled = (data) => (data !== '' && data !== null && data !== undefined);

export const isAlphanumeric = (s) => (/^[a-zA-Z0-9àèéìòùçÀÉÈÌÒÙÇ\s]*$/.test(s));

export const isNumber = (s) => (/^(-?)[0-9]*$/.test(s));

export const isOnlyLettersAndSpaces = (s) => (/^[A-Za-zàèéìòùçÀÉÈÌÒÙÇ\s]*$/.test(s));

export const isCurrency = (s) => (/^(-?)([0-9]+)(?:[.][0-9]{1,2})?$/.test(s));
// to do edit

export const isPercentage = (s) => (/^(-?)([0-9]+)(?:[.][0-9]{2})?$/.test(s));
// to do edit

export const isDate = (dateString) => (!isNaN(Date.parse(dateString)));

export const isValidYear = (dateString, minY, maxY) => {
  let d = new Date(dateString);
  d = d.getFullYear();
  return d >= minY && d <= maxY;
};

export const toCurrency = (value) => (parseFloat(value).toFixed(2));
// to do update

export const isSafe = (s) => (/^[a-zA-z0-9àèéìòùçÀÉÈÌÒÙÇ\s@+-.,:;']*$/.test(s));
// to do update

export const isIva = (s) => (/^(?:IT)?[0-9]{11}$/.test(s));
// to do Luhn

export const isFiscalCode = (s) => (/^[a-zA-Z]{6}\d\d[a-zA-Z]\d\d[a-zA-Z]\d\d\d[a-zA-Z]/.test(s));

export const isCap = (s) => (/^[0-9]{5}$/.test(s));

export const isAteco = (s) => (/^(?:[a-zA-Z][.]?)?([0-9]{2})[.]([0-9]{2})[.]([0-9]{2})$/.test(s));

export const isItalianIban = (s) => (/^IT\d{2}\s?[A-Z]{1}\d{3}\s?\d{4}\s?\d{3}[A-Z0-9]{1}\s?[A-Z0-9]{4}\s?[A-Z0-9]{4}\s?[A-Z0-9]{3}$/.test(s));
