import $oauth2 from '../libs/OAuth2/services/auth';

const getMe = async () => {
  try {
    const me = await $oauth2.getMe();
    if (!me) {
      return null;
    }

    return me;
  } catch (err) {
    return null;
  }
};

// se utente non ha effettuato setup, dopo il login viene reindirizzato a setup

export default async (to, from, next) => {
  const me = await getMe();

  if (to.meta.auth === false && me) {
    return next({ name: 'dashboard' });
  }

  if (to.meta.auth && !me) {
    return next({ name: 'login' });
  }

  return next();
};
