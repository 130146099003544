<template>
  <div class="page">
    <div v-if="isLoadingPage">
      <Loader/>
    </div>
    <div v-else>
      <div v-if="isPage === 0">
        <LoginForm :email="email" @email="updateEmail" />
        <router-link class="footer-link" :to="{ name: 'registration-form' }">
          Non hai ancora un account? <strong>Clicca qui!</strong>
        </router-link>
      </div>

      <div v-else-if="isPage === 1">
        <PasswordResetForm :email="email" @email="updateEmail" />
        <router-link class="footer-link" :to="{ name: 'login' }"
          >Ti ricordi la password? <strong>Accedi!</strong></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/views/components/Forms/LoginForm.vue';
import PasswordResetForm from '@/views/components/Forms/PasswordResetForm.vue';
import Loader from '@/views/components/Layout/Loading.vue';

export default {
  components: {
    Loader,
    LoginForm, // 0
    PasswordResetForm, // 1
  },
  data () {
    return {
      isLoadingPage: true,
      email: '',
      isPage: 0,
      url: '',
    };
  },
  watch: {
    $route: {
      handler () {
        this.updatePage();
      },
      immediate: true,
    },
  },
  methods: {
    updateEmail (newEmailValue) {
      this.email = newEmailValue;
    },
    updatePage: function () {
      if (this.$route.name === 'login') {
        this.isPage = 0;
      } else if (this.$route.name === 'password-reset') {
        this.isPage = 1;
      }
      this.isLoadingPage = false;
    },
  },
  beforeMount () {
    this.updatePage();
  },
};
</script>

<style lang="scss">
.page {
  .footer-link {
    position: absolute;
    left: 0; // ?
    bottom: -50px;
    text-align: center;
    width: 100%;
    margin: 0;
  }
}
</style>
