<template>
  <div>
    <nav class="sidebar d-flex flex-column justify-content-between" :class="{full: showFullSidebar}">
      <div class="logo-wrapper">
        <div class="d-flex justify-content-center my-2 p-3">
          <img :src="logo" :class="{hidden: showFullSidebar}"/>
          <img :src="bigLogo" :class="{hidden: !showFullSidebar}"/>
        </div>

        <ul class="d-flex flex-column justify-content-center p-0" :class="{'no-title' : showFullSidebar}">
          <li class="bottom mb-3" @click="toggleSidebar()" :data-content="!showFullSidebar ? 'Espandi pannello' : 'Comprimi pannello'">
            <div class="icon">
              <i class="fa fa-angle-double-right" :class="{rotated: showFullSidebar}"></i>
            </div>
            <div class="name" :class="{hidden: !showFullSidebar}">
              comprimi pannello
            </div>
          </li>
          <router-link :to="{ name: 'dashboard'}">
            <li data-content="Panoramica">
              <div class="icon">
                <i class="fas fa-coins"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Panoramica
              </div>
            </li>
          </router-link>
          <router-link :to="{ name: 'employees'}" title="Gestione personale">
            <li  data-content="Gestione personale">
              <div class="icon">
                <i class="fas fa-users"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Gestione personale
              </div>
            </li>
          </router-link>
          <router-link :to="{ name: 'loans'}">
            <li data-content="Gestione finanziamenti">
              <div class="icon">
                <i class="fas fa-landmark"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Gestione finanziamenti
              </div>
            </li>
          </router-link>
          <router-link :to="{ name: 'passiveInvoices'}">
            <li data-content="Fatture passive">
              <div class="icon" :class="{'red-dot': notificationsPassiveInvoices > 0 }">
                <i class="fas fa-shopping-cart"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Fatture passive
                <!--
                <div class="notification-sidebar" v-if="notificationsPassiveInvoices > 0">
                  {{notificationsPassiveInvoices}}
                </div>
                -->
              </div>
            </li>
          </router-link>
          <router-link :to="{ name: 'activeInvoices'}">
            <li data-content="Fatture attive">
              <div class="icon" :class="{'red-dot': notificationsActiveInvoices > 0 }">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Fatture attive
                <!--
                <div class="notification-sidebar" v-if="notificationsActiveInvoices > 0">
                  {{notificationsActiveInvoices}}
                </div>
                -->
              </div>
            </li>
          </router-link>
          <router-link :to="{ name: 'scadenzarioAttivo'}">
            <li data-content="Scadenzario attivo">
              <div class="icon">
                <i class="fas fa-book"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Scadenzario attivo
              </div>
            </li>
          </router-link>

          <li @click="showSubmenu = !showSubmenu" class="has-submenu"  data-content="Riconciliazione">
            <div class="icon">
              <i class="fas fa-exchange-alt"></i>
            </div>
            <div class="name w-auto" :class="{hidden: !showFullSidebar}">
              Riconciliazione
            </div>
            <i class="fas fa-angle-down ml-4" :class="[{rotated: showSubmenu}, {hidden: !showFullSidebar}]"></i>

            <!--Visualizzato di lato-->
            <ul :class="{hidden: showFullSidebar}">
              <router-link :to="{ name: 'manualMatching'}">
                <li>
                  Riconciliazione manuale
                </li>
              </router-link>
              <router-link :to="{ name: 'automaticMatching'}">
                <li>
                  Riconciliazione automatica
                </li>
              </router-link>
            </ul>
          </li>

          <!--Visualizzato sotto-->
          <ul class="submenu" v-if="showSubmenu" :class="{hidden: !showFullSidebar}">
            <router-link :to="{ name: 'manualMatching'}">
              <li>
                Manuale
              </li>
            </router-link>
            <router-link :to="{ name: 'automaticMatching'}">
              <li>
                Automatica
              </li>
            </router-link>
          </ul>

          <router-link :to="{ name: 'primeEntry'}">
            <li data-content="Prima nota">
              <div class="icon">
                <i class="fas fa-book"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Prima nota
              </div>
            </li>
          </router-link>
        </ul>

        <div v-if="false" class="btn-container d-flex justify-content-center align-items-center">
          <button type="button" @click="togglePopup('selectMovement')" class="btn btn-add name w-100 d-flex justify-content-center align-items-center">
            <div :class="{hidden: showFullSidebar}">
              <i class="fas fa-plus"></i>
            </div>
            <div class="name w-100" :class="{hidden: !showFullSidebar}">
              Aggiungi
            </div>
          </button>
        </div>

        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-dropdown
            :class="{sm: !showFullSidebar}"
            size="md"
            id="dropdown-1"
            dropright
            variant="primary"
            toggle-class="text-decoration-none"
            offset="-200, 10"
          >
            <template #button-content>
              <div :class="{hidden: showFullSidebar}">
                <i class="fas fa-plus"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Aggiungi movimento
              </div>
            </template>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 0;">Accredito/addebito</b-dropdown-item>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 1;">Bonifico</b-dropdown-item>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 2;">Pagamento fiscale</b-dropdown-item>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 3;">Competenze e oneri bancari</b-dropdown-item>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 4;">Girofondo</b-dropdown-item>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 5;">Ri.Ba o titolo di credito</b-dropdown-item>
            <b-dropdown-item @click="togglePopup('addMovement'); movement = 6;">Versamento/prelievo</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div>
        <ul class="d-flex flex-column justify-content-center p-0 m-0" :class="{'no-title' : showFullSidebar}">
          <li class="bottom" data-content="Impostazioni">
            <div class="icon">
              <i class="fas fa-cog"></i>
            </div>
            <div class="name" :class="{hidden: !showFullSidebar}">
              Impostazioni
            </div>
          </li>
          <li class="bottom" @click="togglePopup('logout')" data-content="Log out">
            <div class="icon">
              <i class="fas fa-sign-out-alt"></i>
            </div>
            <div class="name" :class="{hidden: !showFullSidebar}">
              Log out
            </div>
          </li>

          <div class="company d-flex name w-100 align-items-center justify-content-left user-select-none">
            <i class="fas fa-building" :class="{hidden: showFullSidebar}"></i>
            <div :class="{hidden: !showFullSidebar}" class="d-flex name w-100 flex-column justify-content-center">
              <div class="company-owner">{{ companyName }}</div>
              <div class="vat">P. IVA {{ companyVAT }}</div>
            </div>
          </div>

          <div class="user d-flex align-items-center justify-content-left">
            <div class="circle-wrapper">
              <div class="circle">{{name[0]}}{{surname[0]}}</div>
            </div>
            <div class="name user-select-none" :class="{hidden: !showFullSidebar, 'px-2': showFullSidebar}">
              {{ name }} {{ surname }}
              <div class="user-settings d-inline">
                <i class="fas fa-cog ml-3 light-box"></i>
              </div>
            </div>
          </div>
        </ul>

      </div>
    </nav>

    <Popup @closePopup="togglePopup('logout')" ref="logout" class="md">
      <template #title> Sei sicuro di voler uscire? </template>
      <template #text>Il tuo accont verrà disconnesso.</template>
      <template #button>
        <custom-button
          label="Log out"
          class="name w-100 mt-4"
          @click.prevent.native="logout"
        />
      </template>
    </Popup>

    <Popup @closePopup="togglePopup('selectMovement')" ref="selectMovement" class="md">
      <template #fullContent>
        <SelectMovement :banks="banksList" :companyId="companyId" @save="savedMovement"/>
      </template>
      <!-- to do feedback movimento aggiunto -->
    </Popup>

    <Popup @closePopup="togglePopup('addMovement')" ref="addMovement" class="md">
      <template #fullContent>
        <MovementsWrapper :banks="banksList" :companyId="companyId" :movement="movement" @save="savedMovement"/>
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar_sidebar"/>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

import logo from '@/assets/images/fin-logo-sidebar.svg';
import bigLogo from '@/assets/images/fin-big-logo.svg';
import Popup from '@/views/components/Popup.vue';
import SelectMovement from '@/views/components/Forms/Movements/SelectMovement.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import Button from '@/views/components/Button.vue';
import MovementsWrapper from '@/views/components/Forms/Movements/MovementsWrapper.vue';

export default {
  name: 'Sidebar',
  components: {
    BDropdown,
    BDropdownItem,
    SelectMovement,
    'custom-button': Button,
    MovementsWrapper,
    Popup,
    Snackbar,
  },
  data () {
    return {
      logo: logo,
      bigLogo: bigLogo,
      showFullSidebar: false,
      snackbarText: '',
      showSubmenu: false,
      movement: null,

      name: '',
      surname: '',
      companyId: '',
      companyName: '',
      companyVAT: '',
      banksList: null,

      notificationsPassiveInvoices: 0,
      notificationsActiveInvoices: 0,
    };
  },
  computed: {
    isActive () {
      return this.$router.path;
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.companyVAT = company.vat;
          this.banksList = company.banks;
          this.notificationsPassiveInvoices = company.notifications.fattureOut;
          this.notificationsActiveInvoices = company.notifications.fattureIn;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getMeInfo () {
      const me = this.$oauth2.me;
      this.name = me.name || '';
      this.surname = me.surname || '';
      this.companyName = me.companies[0].name;
      this.companyId = me.companies[0].id;
    },
    logout () {
      this.$router.replace({ name: 'logout' }).catch(() => {});
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    toggleSidebar () {
      this.showFullSidebar = !this.showFullSidebar;
    },
    savedMovement () {
      this.$refs.addMovement.close();
      this.snackbarText = 'Movimento aggiunto correttamente';
      this.$refs.snackbar_sidebar.openSnackbar();
    },
  },
  mounted () {
    this.getMeInfo();
    this.getCompanyInfo();
  },
};
</script>

<style lang="scss">
$sidebar-size: 80px;

.sidebar {
  position: relative;
  height: 100%;
  width: $sidebar-size;
  background-color: white;
  z-index: 10;
  transition: all 0.5s ease-out;
  filter: drop-shadow($box-shadow);

  &.full {
    width: 280px;
    transition: all 0.5s ease-out;
    .icon {
      transform: translateX(-5px);
    }
  }

  .logo-wrapper img {
    transition: all 0.5s ease-in-out;
  }

  ul {
    list-style: none;

    a {
      text-decoration: none;
    }
    .router-link-exact-active {
      background: $primary-lighter;
    }
    &.submenu {
      padding: 0px;
      overflow: hidden;
      li {
        padding-left: 70px;
        width: 100%;
        height: 40px;
        font-size: 14px;
      }
    }
    &.no-title li:hover::after {
      display: none;
    }

    .custom-title {
      position: absolute;
      top: 0;
      left: $sidebar-size;
      font-size: 16px;
      padding: 14px 10px;
      background: white;
      font-weight: 600;
      color: rgba($primary, 0.8);
      border-radius: 0px 10px 10px 0px;
      box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      white-space: nowrap;
      transition: all 0.5s ease-in;
    }

    // hide default title in riconciliazione
    .has-submenu:hover::after {
      display: none;
    }

    li ul {
      padding: 0px;
      display: none;
      background: white;
      margin-left: $sidebar-size;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0px 10px 10px 0px;
      box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      overflow: hidden;
      li {
        overflow: hidden; // u sure?
        font-size: 16px;
        padding: 10px 10px;
        background: white;
        font-weight: 600;
        box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
        white-space: nowrap;
        &:hover {
          color: rgba($primary, 0.8)!important;
          &::after {
            display: none;
          }
        }
      }
      li::after {
        display: none;
      }
    }

    li:hover > ul{
      display: block;
      cursor: pointer;
    }

    li {
      position: relative;
      margin: auto;
      height: 52px;
      // padding: 18px 33px;
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      color: rgba($primary, 0.8)!important;
      font-weight: 600;
      font-size: 16px;
      &::after {
        content: attr(data-content);
        display: none;
        position: absolute;
        top: 0;
        left: $sidebar-size;
        font-size: 16px;
        padding: 14px 10px;
        background: white;
        font-weight: 600;
        color: rgba($primary, 0.8);
        border-radius: 0px 10px 10px 0px;
        white-space: nowrap;
        transition: all 0.5s ease-in;
        box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      }

      &.no-title:hover::after {
        display: none;
      }
      &:hover::after {
        display: inline-block;
      }

      .name {
        overflow: hidden;
      }

      &:hover {
        background: $primary-lighter;
        cursor: pointer;
        .icon {
        &.red-dot {
          i::after {
            border: 2px solid $primary-lighter;
          }
        }
      }
      }
      &.bottom {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        max-height: 45px;
        color: rgba($primary, 0.75);
        i {
          font-size: 16px;
        }
        &::after {
          text-transform: none;
          max-height: inherit;
          padding: 10px;
        }
      }
      .icon {
        min-width: $sidebar-size;
        display: flex;
        justify-content: center;
        position: relative;
        &.red-dot {
          i::after {
            content: '';
            background: red;
            position: absolute;
            top: 10px;
            left: 7px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 2px solid white;
            background: $danger;
            display: block;
          }
        }
      }
      .name {
        position: relative;
        left: -15px;
      }
      i {
        position: relative;
        color: $primary;
        opacity: 0.8;
        transition: all 0.5s ease-out;
      }
      div, a {
        white-space: nowrap;
        transition: all 0.5s ease-out;
        // padding-left: 10px;
      }
    }
  }

  /*
  .notification-sidebar {
    background: $primary-mid;
    padding: 0!important;
    color: white;
    font-weight: 700;
    font-size: 12px;
    width: 18px;
    height: 18px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    min-width: fit-content;
    top: 0px;
    bottom: 0;
    right: 10px;
    margin: auto;
  }
  */

  .company {
    color: $primary;
    background: $primary-lighter;
    height: 100%;
    padding: 10px;
    white-space: nowrap;
    padding-left: 30px;
    overflow: hidden;
    i {
      transition: all 0.5s ease-out;
      font-size: 22px;
      position: absolute;
      margin: auto;
    }
    .company-owner {
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 16px;
      width: 85%;
      overflow: hidden;
    }
    .vat {
      font-weight: 500;
      font-size: 13px;
      opacity: 0.7;
    }
  }

  .user {
    height: 70px;
    font-size: 14px;
    font-weight: 600px;
    color: $primary;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    .circle-wrapper {
      min-width: $sidebar-size;
      display: flex;
      justify-content: center;
      .circle {
        width: 35px;
        height: 35px;
        border-radius: 18px;
        background: $primary-mid;
        opacity: 0.6;
        color: white;
        font-size: 16px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 600;
        color: white;
        cursor: default;
      }
    }
    .name {
      position: relative;
      left: -20px;
      font-weight: 600;
      font-size: 14px;
      color: $primary;
    }
  }

  .btn-container {
    margin: 15px;
    .btn-add {
      padding: 10px;
      background: $primary;
      color: white;
      position: relative;
      i {
        transition: all 0.5s ease-out;
        font-size: 18px;
      }
      div {
        transition: all 0.5s ease-out;
        font-size: 16px;
      }
    }
  }

  .user-settings {
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    background: red;
    width: 100%;
    &.sm button {
      display: flex;
      justify-content: center;
    }
    button {
      overflow: hidden;
      position: absolute;
      left: 10px;
      right: 10px;
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .dropdown-menu li {
    &::after {
      display: none!important;
    }
  }

  // animations
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    width: auto;
  }
  .rotated {
    transform: rotate(180deg);
    transition: all 0.3s ease-out;
  }
  .hidden {
    opacity: 0;
    width: 0px;
    transition: all 0.5s ease-out;
  }
}
</style>
