<template>
  <div class="layout-login">
    <div class="d-flex align-items-center justify-content-center">
      <div class="login-wrapper d-flex flex-column min-vh-100 align-items-center">
        <div
          class="
            flex-grow-1
            d-flex
            align-items-center
            justify-content-center
            logo-wrapper
          "
        >
          <img
            src="@/assets/images/fin-big-logo-white.svg"
          />
        </div>
        <div
          class="form-wrapper flex-shrink-1 d-flex justify-content-center"
        >
          <div class="form bg-white p-4">
            <router-view class="flex-grow-1 w-100 m-0 p-0" />
          </div>
        </div>
        <div class="flex-grow-1 pt-4 d-flex justify-content-center">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import rolesMixin from '@/mixins/roles.js';

export default {
  // mixins: [rolesMixin],
  computed: {
    links () {
      return [];
    },
  },
};
</script>

<style lang="scss">
$background-image: "~@/assets/images/fin-bg.png";
$background-image-2: "~@/assets/images/fin-bg-2.jpg";

$padding: 90px;

.layout-login {
  background-image: url(#{$background-image});
  background-position: center;
  background-color: $primary-lighter;

  .login-wrapper {
    // width: 100%;
    // max-width: 700px;
  }

  .form-wrapper {
    position: relative;

    &::after {
      display: block;
      content: "";
      width: calc(100% + #{($padding * 2)});
      height: 70vh;
      min-height: 100%;
      position: absolute;
      z-index: 2;
      bottom: $padding;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0 0 $padding $padding;
      background-image: url(#{$background-image-2});
      background-size: cover;
      background-position: right;
      box-shadow: 0px 10px 44px rgba($primary, 0.26);
    }

    &::before {
      display: block;
      content: "";
      background: $primary;
      width: calc(100% + #{$padding});
      height: 70vh;
      position: absolute;
      z-index: 1;
      bottom: $padding / 3;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0 0 $padding $padding;
      opacity: 0.5;
      filter: blur(40px);
    }

    .form {
      border-radius: 20px;
      box-shadow: $box-shadow;
      z-index: 3;
      width: 550px;
    }
  }

  .logo-wrapper {
    z-index: 4;
  }
}
</style>
