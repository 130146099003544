<template>
  <div class="login-form">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      :class="showErrors ? 'form-group invalid' : 'form-group'"
    >
      <h2 class="h4 mb-4">Accedi alla tua area riservata</h2>

      <BFormGroup
        class=" mb-4"
        id="input-group-email"
        label="Indirizzo email"
        label-for="input-email"
        :class="{ invalid: !(hideEmailError || isValidEmail) }"
        >
        <BFormInput
          id="input-email"
          v-model="loginEmail"
          type="text"
          @blur="setFeedbackVisibility"
          ref="inputemail"
          autocomplete="email"
          tabindex="1"
          @change="resetErrors"
        ></BFormInput>

        <b-form-invalid-feedback
          :state="false"
          :class="{ invisible: isValidEmail || hideEmailError }"
        >
          <i class="fas fa-exclamation-circle"></i> L'indirizzo email inserito non è valido
        </b-form-invalid-feedback>

      </BFormGroup>

      <BFormGroup
        class="mb-4 group-password"
        id="input-group-password"
        label="Password"
        label-for="input-password"
        :class="{ invalid: !(hidePasswordError || isValidPassword) }"
      >
        <router-link class="forgot-password" :to="{ name: 'password-reset' }" tabindex="3">Password dimenticata?</router-link>
        <BInputGroup>
          <BFormInput
            id="input-password"
            class="input-password"
            v-model="password"
            autocomplete="off"
            @blur="setFeedbackVisibility2"
            v-bind:type="showPassword ? 'text' : 'password'"
            tabindex="2"
            @change="resetErrors"
          >
          </BFormInput>

          <BInputGroupAppend>
            <div class="eye" role="button" @click="showPassword = !showPassword">
                <i v-if="!showPassword" class="fas fa-eye"></i>
                <i v-if="showPassword" class="fas fa-eye-slash"></i>
            </div>
          </BInputGroupAppend>

          <b-form-invalid-feedback
            :state="false"
            :class="{ invisible: isValidPassword || hidePasswordError }"
          >
            <i class="fas fa-exclamation-circle"></i> La password inserita non è valida
          </b-form-invalid-feedback>

        </BInputGroup>

      </BFormGroup>

      <custom-button
          :isLoading="loading"
          label="Accedi"
          class="w-100"
          @click.prevent.native="onSubmit"
          type="submit"
        />
    </form>

    <ErrorCard v-if="errorMessage && showErrors">
      <template #message>
        {{ errorMessage }}
      </template>
    </ErrorCard>

  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';

export default {
  props: {
    email: String,
  },
  components: {
    'custom-button': Button,
    ErrorCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BInputGroupAppend,
  },
  data () {
    return {
      password: '',
      showErrors: false,
      errorMessage: '',
      hideEmailError: true,
      hidePasswordError: true,
      loading: false,
      showPassword: false,
    };
  },
  computed: {
    loginEmail: {
      get: function () {
        return this.email;
      },
      set: function (newEmailValue) {
        this.$emit('email', newEmailValue);
      },
    },
    isValidEmail: function () {
      return this.validateEmail();
    },
    isValidPassword: function () {
      return this.password.length > 0;
    },
  },
  methods: {
    resetErrors: function () {
      this.showErrors = false;
      this.errorMessage = '';
    },
    validateEmail: function () {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    onSubmit: function () {
      if (this.isValidEmail && this.isValidPassword) {
        this.loading = true;
        this.$oauth2
          .loginWithCredentials(this.email, this.password)
          .then(() => {
            this.$router.push({ name: 'dashboard' });
            this.loading = false;
          })
          .catch(e => {
            this.$log.error(e);
            this.loading = false;
            this.showErrors = true;
            this.errorMessage = 'Combinazione di e-mail e password errata';
          })
        ;
      } else {
        this.showErrors = true;
        if (!this.isValidEmail) {
          this.hideEmailError = false;
        } else if (!this.isValidPassword) {
          this.hidePasswordError = false;
        }
      }
    },
    setFeedbackVisibility: function () {
      if (this.email !== '') this.hideEmailError = false;
    },
    setFeedbackVisibility2: function () {
      if (this.password !== '') this.hidePasswordError = false;
    },
  },
};
</script>

<style lang="scss">
.login-form {
  .input-group-password {
    position: relative;
  }
  .input-password {
    border-radius: 10px!important;
  }
  .form-group.invalid {
    color: $red;
    .form-control {
      border: 1px solid $red;
    }
  }
  .invalid-feedback {
    color: $red;
    font-weight: 600;
    line-height: 13px;
    margin-top: 8px;
  }
  .group-password {
    position: relative;
    .recovery-float {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      color: $primary;
      z-index: 1;
    }
  }

  h2 {
    margin-bottom: 40px;
  }
  p {
    display: visible;
  }
  .recovery {
    line-height: 24px;
    text-align: center;
    display: block;
    color: $primary;
  }
  .forgot-password {
    position: absolute;
    top: 0;
    right: 0;
    color: $primary;
    z-index: 5;
  }
  .eye {
    position: relative;
    top: 3px;
  }
}
</style>
