import { render, staticRenderFns } from "./LoadingDots.vue?vue&type=template&id=73b5f0f4&"
var script = {}
import style0 from "./LoadingDots.vue?vue&type=style&index=0&id=73b5f0f4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports