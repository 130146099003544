<template>
<div class="girofondo">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <h2 class="mb-4">Aggiungi Girofondo</h2>

      <BankSelect ref="incomeBankSelect" key="incomeBankSelect" label="Banca a credito" :banks="formattedBanks" @update="updateIncomeBank" @search:blur="showErrorIncomeBank = true" :class="{ invalid: !isValidIncomeBank && showErrorIncomeBank }"/>
        <b-form-invalid-feedback :state="false" :class="{ invisible: isValidIncomeBank || !showErrorIncomeBank }" >
          <i class="fas fa-exclamation-circle"></i> Inserisci una banca
        </b-form-invalid-feedback>

      <BankSelect ref="outcomeBankSelect" key="outcomeBankSelect" label="Banca a debito" :banks="formattedBanks" @update="updateOutcomeBank" @search:blur="showErrorOutcomeBank = true" :class="{ invalid: !isValidOutcomeBank && showErrorOutcomeBank }"/>
        <b-form-invalid-feedback :state="false" :class="{ invisible: isValidOutcomeBank || !showErrorOutcomeBank }" >
          <i class="fas fa-exclamation-circle"></i> Inserisci una banca
        </b-form-invalid-feedback>

      <div class="d-flex row">
        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-amount"
            label="Importo"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount }"
          >
            <b-input-group >
              <CurrencyInput id="input-amount" v-model="amount" @blur="formatAmount" />
            </b-input-group>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-expiration"
            label="Scadenza"
            label-for="input-expiration"
            :class="{ invalid: !isValidExpiration && showErrorExpiration }"
          >
            <b-form-input
              id="input-expiration"
              v-model="expiration"
              type="date"
              @blur="showErrorExpiration = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidExpiration || !showErrorExpiration }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una scadenza valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <custom-button
          :isLoading="loading"
          label="Aggiungi"
          class="w-100"
          @click.prevent.native="onSubmit"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
</div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import BankSelect from '@/views/components/BankSelect.vue';
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isValidYear } from '@/helpers/formValidation.js';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    in_out: String,
    banks: Array,
    companyId: String,
  },
  components: {
    BankSelect,
    CurrencyInput,
    ErrorCard,
    'custom-button': Button,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-form-invalid-feedback': BFormInvalidFeedback,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorIncomeBank: false,
      showErrorOutcomeBank: false,
      showErrorAmount: false,
      showErrorExpiration: false,
      loading: false,
      errorMessage: '',

      // Form
      bank: null,
      incomeBank: null,
      outcomeBank: null,
      amount: null,
      expiration: null,
      sign: null,
    };
  },
  computed: {
    isValidIncomeBank () {
      return isFilled(this.incomeBank);
    },
    isValidOutcomeBank () {
      return isFilled(this.outcomeBank);
    },
    formattedBanks () {
      return this.banks.filter(obj => obj.isCash !== true);
    },
    bankInfo () {
      return this.banks.find(obj => obj.name === this.bank);
    },
    isValidForm () {
      return this.isValidIncomeBank && this.isValidOutcomeBank && this.isValidAmount && this.isValidExpiration;
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidExpiration () {
      return isDate(this.expiration) && isValidYear(this.expiration, 2000, 2100);
    },
  },
  methods: {
    updateIncomeBank (bank) {
      this.incomeBank = bank;
    },
    updateOutcomeBank (bank) {
      this.outcomeBank = bank;
    },
    formatAmount: function () {
      this.amount = Math.abs(this.amount);
      this.showErrorAmount = true;
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.loading = true;
        this.formatAmount();
        this.$api.createPromise(this.companyId, null, parseFloat(this.amount), this.expiration, null, null, null, { superType: 'girofondi', sign: this.sign, bankIdIn: this.incomeBank.id, bankIdOut: this.outcomeBank.id })
          .then((res) => {
            // this.$log.debug(res);
            this.$emit('save', 'addebiti_accrediti');
            this.$root.$emit('updateMovements');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = 'Qualcosa è andato storto. Controlla i dati inseriti';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidIncomeBank) {
          this.showErrorIcomeBank = true;
        } else if (!this.isValidOutcomeBank) {
          this.showErrorOutcomeBank = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidExpiration) {
          this.showErrorExpiration = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
};
</script>
