<template>
  <AwlLoader :imagePath="require('@/assets/images/fin-big-logo.svg')" animated fitViewport imageClasses="logo"></AwlLoader>
</template>

<script>

export default {
  components: {
    AwlLoader: () => import('../../../libs/Loader/components/AwlBouncingLoader'),
  },
};

</script>

<style lang="scss">

.awl-loader {
  background: #fff;
  .logo {
    max-width: 85px;
  }
}

</style>
