<template>
    <div class="pagamento_bonifico">
        <form
          @submit.prevent="onSubmit"
          class="text-start"
          novalidate
          >
          <h2 class="mb-4">Aggiungi pagamento bonifico</h2>

          <BankSelect ref="bankSelect" key="bankSelect" :banks="formattedBanks" @update="updateBank" @search:blur="showErrorBank = true" :class="{ invalid: !isValidBank && showErrorBank }"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
            </b-form-invalid-feedback>

          <b-form-group
            class="mb-3"
            id="input-group-counterpart"
            label="Controparte"
            label-for="input-counterpart"
            :class="{ invalid: !isValidCounterpart && showErrorCounterpart }"
          >
            <b-form-input
              id="input-counterpart"
              v-model="counterpart"
              type="text"
              @blur="showErrorCounterpart = true"
              placeholder="Mario Rossi"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidCounterpart || !showErrorCounterpart }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci la controparte
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex row">
            <div class="col-6">
              <b-form-group
                class="mb-3"
                id="input-group-amount"
                label="Importo"
                label-for="input-amount"
                :class="{ invalid: !isValidAmount && showErrorAmount }"
              >
                <b-input-group class="prepend">
                  <template #prepend>
                    <i v-if="in_out === 'in'" class="fas fa-plus-circle green" />
                    <i v-if="in_out === 'out'" class="fas fa-minus-circle red" />
                  </template>
                  <CurrencyInput id="input-amount" v-model="amount" @blur="formatAmount" :class="in_out === 'in' ? 'in' : 'out'" />
                </b-input-group>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                class="mb-3"
                id="input-group-expiration"
                label="Scadenza"
                label-for="input-expiration"
                :class="{ invalid: !isValidExpiration && showErrorExpiration }"
              >
                <b-form-input
                  id="input-expiration"
                  v-model="expiration"
                  type="date"
                  @blur="showErrorExpiration = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidExpiration || !showErrorExpiration }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una scadenza valida
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <b-form-group
            class="mb-4"
            id="input-group-description"
            label="Causale"
            label-for="input-description"
            :class="{ invalid: !isValidDescription && showErrorDescription }"
          >
            <textarea
              id="input-description"
              class="form-control"
              rows="3"
              v-model="description"
              @blur="showErrorDescription = true"
            ></textarea>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDescription || !showErrorDescription }" >
              <i class="fas fa-exclamation-circle"></i>{{ description.length > 250 ? 'Puoi inserire fino a 250 caratteri' : 'Inserisci una descrizione valida' }}
            </b-form-invalid-feedback>
          </b-form-group>

          <custom-button
              :isLoading="loading"
              label="Aggiungi"
              class="w-100"
              @click.prevent.native="onSubmit"
            />

          <ErrorCard v-if="errorMessage">
            <template #message >
              {{ errorMessage }}
            </template>
          </ErrorCard>
        </form>
    </div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isAlphanumeric, isCurrency, isDate, isValidYear, isSafe } from '@/helpers/formValidation.js';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import BankSelect from '@/views/components/BankSelect.vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    in_out: String,
    banks: Array,
    companyId: String,
  },
  components: {
    BankSelect,
    CurrencyInput,
    ErrorCard,
    'custom-button': Button,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-form-invalid-feedback': BFormInvalidFeedback,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorBank: false,
      showErrorCounterpart: false,
      showErrorAmount: false,
      showErrorExpiration: false,
      showErrorDescription: false,
      loading: false,
      errorMessage: '',

      // Form
      bank: '',
      counterpart: '',
      amount: null,
      expiration: null,
      description: '',
      sign: 'outcome',
    };
  },
  computed: {
    formattedBanks () {
      return this.banks.filter(obj => obj.isCash !== true);
    },
    bankInfo () {
      return this.banks.find(obj => obj.name === this.bank);
    },
    isValidForm () {
      return this.isValidBank && this.isValidCounterpart && this.isValidAmount && this.isValidExpiration && this.isValidDescription;
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidCounterpart () {
      return isFilled(this.counterpart) && isAlphanumeric(this.counterpart);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidExpiration () {
      return isDate(this.expiration) && isValidYear(this.expiration, 2000, 2100);
    },
    isValidDescription () {
      return isSafe(this.description) && this.description.length <= 250;
    },
  },
  methods: {
    updateBank (bank) {
      this.bank = bank;
    },
    formatAmount: function () {
      if (this.sign === 'income') {
        this.amount = Math.abs(this.amount);
      } else {
        this.amount = -1 * Math.abs(this.amount);
      }
      this.showErrorAmount = true;
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.loading = true;
        this.formatAmount();
        this.$api.createPromise(this.companyId, this.counterpart, parseFloat(this.amount), this.expiration, this.description, null, this.bank.id, { superType: 'altri_bonifici', sign: 'outcome' })
          .then((res) => {
            // this.$log.debug(res);
            this.$emit('save', 'altri_bonifici');
            this.$root.$emit('updateMovements');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = 'Qualcosa è andato storto. Controlla i dati inseriti';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidCounterpart) {
          this.showErrorCounterpart = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidExpiration) {
          this.showErrorExpiration = true;
        } else if (!this.isValidDescription) {
          this.showErrorDescription = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
};
</script>
